<template>
    <div v-loading="load" class="info-list">
        <Header/>
        <div class="main-page-content contain-left-right">
            <div class="left-content" style="width:1200px">
                <img v-if="obj.image" :src="obj.image" style="width:100%">
                <div v-if="obj.content" class="content_con" v-html="obj.content"> 
                </div>
                <div class="btn" @click="$router.push('/hezuo')">合作流程</div>
            </div>
            
        </div>
        <div class="blank20"/>
        <Footer/>
    </div>
</template>
<script>
export default {
    data() {
        return {
            obj:{},
            load:false,
        }
    },
    components:{
    },
    async beforeMount() {
        if(this.$route.query.id){
            this.load = true;
            const res = await this.$http.get('/api/index/getSchoolEntParnterDetail',{id:this.$route.query.id});
            this.load = false;
            if(res){
                this.obj = res;
            }
        }
    },
    created() {
        // if(this.$route.params.id){
        //     this.type = this.$route.params.type;
        // }
    },
}
</script>
<style lang="scss" scoped>
.tit-list {
    height: 50px;
    line-height: 50px;
}
.weizhi{
    font-size: 14px;
    line-height: 50px;
    color: #224899;
    margin-left: 10px;
}
.content_header{ 
    padding:20px 0px 20px;
    h1{ font-size:22px; color:#000; height:40px; line-height:40px;}
    .header_msg{ 
        color:#999; font-size:14px; padding:5px 0 15px 0; 
        i{ margin:0 3px;}
    }
}
.weizhi a {
    font-size: 14px;
    line-height: 50px;
    padding: 0 5px;
    color: #606266;
}
a, a:link {
    cursor: pointer;
    color: #333;
    text-decoration: none;
}
a:hover{
    color: #224899;
}

.btn{
    cursor: pointer;
    width:180px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    color: #fff;
    margin:auto;
    margin-top: 20px;
    background: #0087F2;
}
</style>
